import { getLocal, saveLocal } from "../encrypt";
import Swal from "sweetalert2";
import { NotifError } from "../GlobalComponent/notification";

export const GET_SUM_BRUTO = "GET_SUM_BRUTO";
export const GET_SUM_NETTO = "GET_SUM_NETTO";
export const GET_SUM_GROSS = "GET_SUM_GROSS";
export const GET_SUM_RP = "GET_SUM_RP";
export const GET_SUM_P = "GET_SUM_P";
export const GET_SUM_K = "GET_SUM_K";
export const GET_SUM_PKG = "GET_SUM_PKG";
export const GET_SUM_QTY = "GET_SUM_QTY";
export const PROGRESS_SEND = "PROGRESS_SEND";
export const FINISH_SEND = "FINISH_SEND";

export const getBruto = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.bruto ? list.bruto : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_BRUTO,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getNetto = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.netto ? list.netto : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_NETTO,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getGross = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.gross ? list.gross : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_GROSS,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getRp = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.rp ? list.rp : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_RP,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getP = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.p ? list.p : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_P,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getK = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.k ? list.k : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_K,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getPkg = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.pkg ? list.pkg : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_PKG,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const getQty = (nama) => {
  return (dispatch) => {
    var isi = JSON.parse(localStorage.getItem(nama)) || [];
    var sum = isi
      .map((list) => parseFloat(list.qty ? list.qty : 0))
      .reduce((a, b) => a + b, 0);
    console.log(sum);
    dispatch({
      type: GET_SUM_QTY,
      payload: {
        data: sum,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const progressSend = () => {
  return (dispatch) => {
    dispatch({
      type: PROGRESS_SEND,
      payload: {
        data: true,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const finishSend = () => {
  return (dispatch) => {
    dispatch({
      type: FINISH_SEND,
      payload: {
        data: false,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const isExist = ({
  name,
  target,
  value,
  payload,
  target2,
  value2,
  replace = false,
}) => {
  return new Promise(async (resolve, reject) => {
    const data = await getLocal(name);
    let index = data.findIndex(
      (x) => x[target] === value && x[target2] === value2
    );
    console.log("DELETE BEFORE INDEX:", index);
    if (!replace) {
      index = -1;
    }
    if (index >= 0) {
      Swal.fire({
        title: "Data Sudah Ada !",
        text: "Anda Akan Mengganti Dengan Data Ini ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ganti!",
        cancelButtonText: "Tidak",
      }).then(async (result) => {
        if (result.isConfirmed) {
          data.splice(index, 1, payload);
          await saveLocal(name, data, []);
          resolve("REPLACED");
        } else {
          resolve("DECLINED");
        }
      });
    } else {
      const result = [...data, payload];
      await saveLocal(name, result, []);
      resolve("STORED");
    }
  });
};

export const onlyExist = ({
  name,
  target,
  value,
  payload,
  target2,
  value2,
  replace = false,
}) => {
  return new Promise(async (resolve, reject) => {
    const data = await getLocal(name);
    let index = data.findIndex((x) => x[target] === value);
    let indexTwo = data.findIndex((x) => x[target2] === value2);
    console.log("DELETE BEFORE INDEX:", index);
    if (!replace) {
      index = -1;
    }
    if (data.length === 0) {
      const result = [...data, payload];
      await saveLocal(name, result, []);
      resolve("STORED");
    } else {
      if (index >= 0) {
        if (indexTwo >= 0) {
          const findDataIndex = data.findIndex(
            (x) => x[target] === value && x[target2] === value2
          );
          Swal.fire({
            title: "Data Sudah Ada !",
            text: "Anda Akan Mengganti Dengan Data Ini ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ganti!",
            cancelButtonText: "Tidak",
          }).then(async (result) => {
            if (result.isConfirmed) {
              data.splice(findDataIndex, 1, payload);
              await saveLocal(name, data, []);
              resolve("REPLACED");
            } else {
              resolve("DECLINED");
            }
          });
        } else {
          const result = [...data, payload];
          await saveLocal(name, result, []);
          resolve("STORED");
        }
      } else {
        NotifError("Kode Customer Harus Sama");
        resolve("DECLINED");
      }
    }
  });
};

export const isExistSingle = ({ name, target, value, payload }) => {
  return new Promise(async (resolve, reject) => {
    const data = await getLocal(name);
    const result = data.findIndex((x) => x[target] === value);
    if (result >= 0) {
      Swal.fire({
        title: "Data Sudah Ada !",
        text: "Anda Akan Mengganti Dengan Data Ini ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ganti!",
        cancelButtonText: "Tidak",
      }).then(async (x) => {
        if (x.isConfirmed) {
          data.splice(result, 1, payload);
          await saveLocal(name, data, []);
          resolve("REPLACED");
        } else {
          resolve("DECLINED");
        }
      });
    } else {
      const result = [...data, payload];
      await saveLocal(name, result, []);
      resolve("STORED");
    }
  });
};

export const deleteLocalTempNew = (nama, index, getFunction) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = JSON.parse(localStorage.getItem(nama)) || [];
    data.splice(index, 1);
    localStorage.setItem(nama, JSON.stringify(data));
    setTimeout(() => {
      dispatch(getFunction);
      dispatch(finishSend());
    }, 500);
  };
};
export const deleteLocalTemp = (nama, index, getFunction) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = await getLocal(nama);
    data.splice(index, 1);
    saveLocal(nama, data).then(() => {
      dispatch(getFunction);
      dispatch(finishSend());
    });
  };
};
export const deleteLocalTempWithoutFunc = (nama, index) => {
  const data = JSON.parse(localStorage.getItem(nama)) || [];
  data.splice(index, 1);
  localStorage.setItem(nama, JSON.stringify(data));
};

export const checkIsExist = async ({ target, value, localName }) => {
  const data = JSON.parse(localStorage.getItem(localName)) || [];
  let result = data.findIndex((x) => x[target] === value);
  return result >= 0;
};
