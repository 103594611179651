import { AxiosMasterGet } from "../Axios";
import { checkLaporan, getToday } from "../helper";
import { finishSend, progressSend } from "./LogicAction";

export const GET_VALIDASI_CUSTOMER_PENJUALAN =
  "GET_VALIDASI_CUSTOMER_PENJUALAN";
export const GET_VALIDASI_CUSTOMER_PENJUALAN_24K =
  "GET_VALIDASI_CUSTOMER_PENJUALAN_24K";
export const GET_VALIDASI_CUSTOMER_CORRECTION_NOTE =
  "GET_VALIDASI_CUSTOMER_CORRECTION_NOTE";
export const GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON =
  "GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON";
export const GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER =
  "GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER";
export const GET_VALIDASI_CUSTOMER_TOLAK_RETURN_CUSTOMER =
  "GET_VALIDASI_CUSTOMER_TOLAK_RETURN_CUSTOMER";
export const GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN =
  "GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN";
export const GET_VALIDASI_CUSTOMER_TITIP_GR_CUSTOMER =
  "GET_VALIDASI_CUSTOMER_TITIP_GR_CUSTOMER";
export const GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM =
  "GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM";
export const GET_VALIDASI_CUSTOMER_PEMBELIAN_LM =
  "GET_VALIDASI_CUSTOMER_PEMBELIAN_LM";
export const GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE =
  "GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE";
export const GET_VALIDASI_CUSTOMER_SALDO_AWAL =
  "GET_VALIDASI_CUSTOMER_SALDO_AWAL";

export const getValidasiCustomerAll = () => {
  return (dispatch) => {
    AxiosMasterGet(`validate-customer/get/un-valid-data/ALL&${getToday()}`)
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getValidasiCustomerAllFilterCust = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerPenjualan = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PENJUALAN")
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PENJUALAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PENJUALAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerPenjualan24K = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PENJUALAN 24K")
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN_24K,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "PENJUALAN 24K"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PENJUALAN_24K,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "PENJUALAN 24K"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PENJUALAN_24K,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getValidasiCustomerCorrectionNote = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "CORRECTION NOTE")
          )
        ) {
          dispatch(finishSend());
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "CORRECTION NOTE"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "CORRECTION NOTE"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getValidasiCustomerKirimStockRetur = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "KIRIM STOKC RETUR")
          )
        ) {
          dispatch(finishSend());
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "KIRIM STOCK RETUR"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "KIRIM STOCK RETUR"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getValidasiCustomerReturnPenjualan = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(res.data.filter((list) => list.keterangan === "RETUR"))
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN,
            payload: {
              data: res.data.filter((list) => list.keterangan === "RETUR"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN,
            payload: {
              data: res.data.filter((list) => list.keterangan === "RETUR"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerPembayaranPerbon = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PEMBAYARAN")
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PEMBAYARAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
            payload: {
              data: res.data.filter((list) => list.keterangan === "PEMBAYARAN"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerTolakPembayaranCustomer = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "TOLAKAN CUSTOMER")
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "TOLAKAN CUSTOMER"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "TOLAKAN CUSTOMER"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerTolakPembayaranReturn = () => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet("cst/dc/rtr/heads?statusValid=OPEN")
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerTitipGrCustomer = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter(
              (list) =>
                list.keterangan === "TAMBAH TITIPAN" ||
                list.keterangan === "AMBIL TITIPAN"
            )
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
            payload: {
              data: res.data.filter(
                (list) =>
                  list.keterangan === "TAMBAH TITIPAN" ||
                  list.keterangan === "AMBIL TITIPAN"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
            payload: {
              data: res.data.filter(
                (list) =>
                  list.keterangan === "TAMBAH TITIPAN" ||
                  list.keterangan === "AMBIL TITIPAN"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
export const getValidasiCustomerPembayaranLM = () => {
  return (dispatch) => {
    dispatch(progressSend());
    AxiosMasterGet("cst/dc/lm/heads?statusValid=OPEN")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.tipeTransaksi === "JUAL LM")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM,
            payload: {
              data: res.data.filter((list) => list.tipeTransaksi === "JUAL LM"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM,
            payload: {
              data: res.data.filter((list) => list.tipeTransaksi === "JUAL LM"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getValidasiCustomerPembelianLM = () => {
  return (dispatch) => {
    AxiosMasterGet("cst/dc/lm/heads?statusValid=OPEN")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.tipeTransaksi === "BELI LM")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBELIAN_LM,
            payload: {
              data: res.data.filter((list) => list.tipeTransaksi === "BELI LM"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_PEMBELIAN_LM,
            payload: {
              data: res.data.filter((list) => list.tipeTransaksi === "BELI LM"),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_PEMBELIAN_LM,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getvalidasiCustomerTranseferBalance = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "TRANSFER BALANCE")
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "TRANSFER BALANCE"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "TRANSFER BALANCE"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};

export const getvalidasiCustomerSaldoAwal = (
  kode_supplier,
  tglTo = getToday()
) => {
  return (dispatch) => {
    AxiosMasterGet(
      `validate-customer/get/un-valid-data/${kode_supplier}&${tglTo}`
    )
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "SALDO AWAL")
          )
        ) {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_SALDO_AWAL,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch(finishSend());
          dispatch({
            type: GET_VALIDASI_CUSTOMER_SALDO_AWAL,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch(finishSend());
        dispatch({
          type: GET_VALIDASI_CUSTOMER_SALDO_AWAL,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        });
      });
  };
};
