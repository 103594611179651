import { toast } from "react-toastify";
import { AxiosMasterGet, AxiosMasterPut } from "../Axios";
// import { getLocal, saveLocal } from "../encrypt";
import { checkLaporan, multipleDeleteLocal } from "../helper";
import { finishSend, progressSend } from "./LogicAction";
import { ToastKirim } from "./toast";
import { change, reset } from "redux-form";

export const GET_VALIDASI_BARANG_PINDAH_BARANG =
  "GET_VALIDASI_BARANG_PINDAH_BARANG";
export const GET_VALIDASI_BARANG_MUTASI_BARANG =
  "GET_VALIDASI_BARANG_MUTASI_BARANG";
export const GET_VALIDASI_KIRIM_STOCK = "GET_VALIDASI_KIRIM_STOCK";
export const GET_VALIDASI_BARANG_MUTASI_LK = "GET_VALIDASI_BARANG_MUTASI_LK";
export const GET_VALIDASI_BARANG_KIRIM_MASAK =
  "GET_VALIDASI_BARANG_KIRIM_MASAK";
export const GET_VALIDASI_BARANG_TERIMA_MASAK =
  "GET_VALIDASI_BARANG_TERIMA_MASAK";
export const GET_VALIDASI_BARANG_SALDO_AWAL_STOCK =
  "GET_VALIDASI_BARANG_SALDO_AWAL_STOCK";
export const GET_VALIDASI_BARANG_SALDO_AWAL_CT =
  "GET_VALIDASI_BARANG_SALDO_AWAL_CT";
export const GET_VALIDASI_BARANG_SALDO_AWAL_24K =
  "GET_VALIDASI_BARANG_SALDO_AWAL_24K";
export const SEND_SELECTED = "SEND_SELECTED";

export const getValidasiBarangPindahBarang = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "PINDAH BARANG")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "PINDAH BARANG"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "PINDAH BARANG"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_PINDAH_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangMutasiBarang = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "MUTASI STOCK")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "MUTASI STOCK"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_PINDAH_BARANG,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "MUTASI STOCK"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_PINDAH_BARANG,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangKirimStock = () => {
  return (dispatch) => {
    AxiosMasterGet("/kirim-stock/get/UnValidAll")
      .then((res) => {
        if (checkLaporan(res.data)) {
          dispatch({
            type: GET_VALIDASI_KIRIM_STOCK,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_KIRIM_STOCK,
            payload: {
              data: res.data,
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_KIRIM_STOCK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangMutasiLK = () => {
  return (dispatch) => {
    AxiosMasterGet("item/dc/mutasilk/heads?statusValid=OPEN")
      .then((res) =>
        dispatch({
          type: GET_VALIDASI_BARANG_MUTASI_LK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_MUTASI_LK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangKirimMasak = () => {
  return (dispatch) => {
    AxiosMasterGet("item/dc/kirimmasak/heads?statusValid=OPEN")
      .then((res) =>
        dispatch({
          type: GET_VALIDASI_BARANG_KIRIM_MASAK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_KIRIM_MASAK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangTerimaMasak = () => {
  return (dispatch) => {
    AxiosMasterGet("item/dc/terimamasak/heads?statusValid=OPEN")
      .then((res) =>
        dispatch({
          type: GET_VALIDASI_BARANG_TERIMA_MASAK,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_TERIMA_MASAK,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangSaldoAwalStock = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "SALDO AWAL STOCK")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_SALDO_AWAL_STOCK,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL STOCK"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_SALDO_AWAL_STOCK,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL STOCK"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_SALDO_AWAL_STOCK,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangSaldoAwalCT = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "SALDO AWAL CT")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_SALDO_AWAL_CT,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL CT"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_SALDO_AWAL_CT,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL CT"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_SALDO_AWAL_CT,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getValidasiBarangSaldoAwal24K = () => {
  return (dispatch) => {
    AxiosMasterGet("validate-stock/get/un-valid-data")
      .then((res) => {
        if (
          checkLaporan(
            res.data.filter((list) => list.keterangan === "SALDO AWAL 24K")
          )
        ) {
          dispatch({
            type: GET_VALIDASI_BARANG_SALDO_AWAL_24K,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL 24K"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        } else {
          dispatch({
            type: GET_VALIDASI_BARANG_SALDO_AWAL_24K,
            payload: {
              data: res.data.filter(
                (list) => list.keterangan === "SALDO AWAL 24K"
              ),
              alert:
                "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
              error: false,
            },
          });
        }
      })
      .catch(() =>
        dispatch({
          type: GET_VALIDASI_BARANG_SALDO_AWAL_24K,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const saveSelectedTemp = (row, isSelect) => {
  return (dispatch, getState) => {
    dispatch(progressSend());
    let array = JSON.parse(localStorage.getItem("FakturTerpilih"));
    let array2 = JSON.parse(localStorage.getItem("FakturTerpilih_kirim"));
    const data = {
      tanggal: row.tanggal,
      no_bon: row.no_bon,
      total_bruto: row.total_bruto,
      total_netto: row.total_netto,
      keterangan: row.keterangan,
      no_trx: row.no_trx,
    };
    const kirim = {
      no_trx: row.no_trx,
      keterangan: row.keterangan,
    };
    if (isSelect) {
      var index1 = array.findIndex((item, i) => {
        return item.no_trx === row.no_trx;
      });
      var index2 = array2.findIndex((item, i) => {
        return item.no_trx === row.no_trx;
      });
      if (index1 < 0) {
        array.push(data);
        array2.push(kirim);
      } else {
        array.splice(index1, 1);
        array2.splice(index2, 1);
      }
      localStorage.setItem("FakturTerpilih", JSON.stringify(array));
      localStorage.setItem("FakturTerpilih_kirim", JSON.stringify(array2));
      dispatch({
        type: SEND_SELECTED,
        payload: { data: array.length },
      });
    } else {
      var index = array.findIndex((item, i) => {
        return item.no_trx === row.no_trx;
      });
      var index3 = array.findIndex((item, i) => {
        return item.no_trx === row.no_trx;
      });
      array.splice(index, 1);
      array2.splice(index3, 1);
      localStorage.setItem("FakturTerpilih", JSON.stringify(array));
      localStorage.setItem("FakturTerpilih_kirim", JSON.stringify(array2));
      dispatch({
        type: SEND_SELECTED,
        payload: { data: array.length },
      });
    }
    dispatch(finishSend());
  };
};

export const saveOnSelectAllBarang = (isSelect, rows) => {
  return (dispatch, getState) => {
    dispatch(progressSend());
    var array = [];
    var array2 = [];
    rows.forEach(function (list) {
      var data = {
        tanggal: list.tanggal,
        no_bon: list.no_bon,
        total_bruto: list.total_bruto,
        total_netto: list.total_netto,
        keterangan: list.keterangan,
        no_trx: list.no_trx,
      };
      const kirim = {
        no_trx: list.no_trx,
        keterangan: list.keterangan,
      };
      array.push(data);
      array2.push(kirim);
    });
    if (isSelect) {
      localStorage.setItem("FakturTerpilih", JSON.stringify(array));
      localStorage.setItem("FakturTerpilih_kirim", JSON.stringify(array2));
      dispatch({
        type: SEND_SELECTED,
        payload: { data: array.length },
      });
    } else {
      multipleDeleteLocal(["FakturTerpilih", "FakturTerpilih_kirim"]);
      dispatch({
        type: SEND_SELECTED,
        payload: { data: 0 },
      });
    }
    dispatch(finishSend());
  };
};

export const changeValidasiBarang = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    // const state = getState();
    // // const selected = state.form.validasiBarang.values;
    // // console.log(selected.tipeTransaksi);

    switch (kode) {
      case "Pindah Barang":
        dispatch(getValidasiBarangPindahBarang());
        break;
      case "MuatasiBarang":
        dispatch(getValidasiBarangMutasiBarang());
        break;
      case "Kirim Stock":
        dispatch(getValidasiBarangKirimStock());
        break;
      case "MutasiJenisLK":
        dispatch(getValidasiBarangMutasiLK());
        break;
      case "Kirim Masak":
        dispatch(getValidasiBarangKirimMasak());
        break;
      case "Terima Masak":
        dispatch(getValidasiBarangTerimaMasak());
        break;
      case "SALDO AWAL STOCK":
        dispatch(getValidasiBarangSaldoAwalStock());
        break;
      case "SALDO AWAL CT":
        dispatch(getValidasiBarangSaldoAwalCT());
        break;
      case "SALDO AWAL 24K":
        dispatch(getValidasiBarangSaldoAwal24K());
        break;
      default:
        // console.log("ERROR");
        break;
    }
    dispatch(finishSend());
  };
};

export const sendValidasiBarang = (data) => {
  return async (dispatch) => {
    dispatch(progressSend());
    var hasil = {
      trx_stock: JSON.parse(localStorage.getItem("FakturTerpilih_kirim")),
    };
    try {
      await toast.promise(
        AxiosMasterPut("validate-stock/validate", hasil),
        ToastKirim
      );
    } catch (e) {
      dispatch(finishSend());
    }
    multipleDeleteLocal(["FakturTerpilih", "FakturTerpilih_kirim"]);
    dispatch(reset("validasiBarang"));
    dispatch(change("validasiBarang", "tipeTransaksi", "Pindah Barang"));
    dispatch(finishSend());
    dispatch(getValidasiBarangPindahBarang());
    dispatch({
      type: SEND_SELECTED,
      payload: { data: 0 },
    });
  };
};
export const sendBatalValidasiBarang = (data) => {
  return async (dispatch) => {
    dispatch(progressSend());
    var hasil = {
      trx_stock: JSON.parse(localStorage.getItem("FakturTerpilih_kirim")),
    };
    try {
      await toast.promise(
        AxiosMasterPut("validate-stock/batal", hasil),
        ToastKirim
      );
    } catch (e) {
      dispatch(finishSend());
    }
    multipleDeleteLocal(["FakturTerpilih", "FakturTerpilih_kirim"]);
    dispatch(reset("validasiBarang"));
    dispatch(change("validasiBarang", "tipeTransaksi", "Pindah Barang"));
    dispatch(finishSend());
    dispatch(getValidasiBarangPindahBarang());
    dispatch({
      type: SEND_SELECTED,
      payload: { data: 0 },
    });
  };
};
