import { getLocal, saveLocal } from "../../../encrypt";
import { AxiosMasterGet, AxiosMasterGetWithParams } from "../../../Axios";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { purifiedData } from "../../../helper";

export const GET_ALL_CETAK_BARCODE_SAMPLE = "GET_ALL_CETAK_BARCODE_SAMPLE";

export const getCetakBarcodeSample = (type, filter) => {
  return async (dispatch, getState) => {
    const response = type
      ? await toast.promise(AxiosMasterGet("sampel"), ToastKirim)
      : await toast.promise(
          AxiosMasterGetWithParams("perhiasan", {
            type: filter ? "MUTASI" : "PACKING",
          }),
          ToastKirim
        );
    dispatch({
      type: GET_ALL_CETAK_BARCODE_SAMPLE,
      payload: response.data.result,
    });
  };
};

export const getCetakBarcodeSampleV2 = (type, filter, typeName) => {
  return async (dispatch, getState) => {
    const response =
      typeName === "alloy"
        ? await toast.promise(AxiosMasterGet("alloy"), ToastKirim)
        : type
        ? await toast.promise(AxiosMasterGet("sampel"), ToastKirim)
        : await toast.promise(
            AxiosMasterGetWithParams("perhiasan", {
              type: filter ? "MUTASI" : "PACKING",
            }),
            ToastKirim
          );
    dispatch({
      type: GET_ALL_CETAK_BARCODE_SAMPLE,
      payload: typeName === "alloy" ? response.data : response.data.result,
    });
  };
};

export const onSelectRow = (row, isSelect) => {
  return async (dispatch, getState) => {
    let array = await getLocal("FakturTerpilih");
    const kirim = {
      kode_barcode: row.kode_barcode,
    };
    if (isSelect) {
      var index1 = array.findIndex((item, i) => {
        return item.kode_barcode === row.kode_barcode;
      });
      if (index1 < 0) {
        array.push(kirim);
      } else {
        array.splice(array, 1);
      }
      await saveLocal("FakturTerpilih", array);
    } else {
      var index = array.findIndex((item, i) => {
        return item.kode_barcode === row.kode_barcode;
      });
      array.splice(index, 1);
      await saveLocal("FakturTerpilih", array);
    }
  };
};

export const onSelectAllRow = (rows, isSelect) => {
  return async (dispatch, getState) => {
    var array = [];
    rows.forEach(function (list) {
      const kirim = {
        kode_barcode: list.kode_barcode,
      };
      array.push(kirim);
    });
    if (isSelect) {
      await saveLocal("FakturTerpilih", array);
    } else {
      localStorage.removeItem("FakturTerpilih");
    }
  };
};

export const sendCetakBarcode = () => {
  return async (dispatch, getState) => {
    const data = await getLocal("FakturTerpilih");
    let feedback = data.map((x) => x.kode_barcode).toString();
    document.getElementById("myInput").value = feedback;
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "autoprint_barcode.txt";
    document.body.appendChild(element);
    element.click();
  };
};

export const getFiltered = (type) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.CetakBarcodeSample.values;
    purifiedData(selected);
    const filter = {
      tgl_from: selected.tgl_from,
      tgl_to: selected.tgl_to,
      barcode_from: selected.barcode_from,
      barcode_to: selected.barcode_to,
      lokasi: selected.lokasi,
    };
    const response = type
      ? await AxiosMasterGetWithParams("sampel", filter)
      : await AxiosMasterGetWithParams("perhiasan", {
          type: selected.type === "perhiasan" ? "MUTASI" : "PACKING",
          ...filter,
        });
    dispatch({
      type: GET_ALL_CETAK_BARCODE_SAMPLE,
      payload: response.data.result,
    });
  };
};

export const getFilteredV2 = (type, typeName) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selected = state.form.CetakBarcodeSample.values;
    purifiedData(selected);
    const filter = {
      tgl_from: selected.tgl_from,
      tgl_to: selected.tgl_to,
      barcode_from: selected.barcode_from,
      barcode_to: selected.barcode_to,
      lokasi: selected.lokasi,
    };
    const filterAlloy = {
      tgl_from: selected.tgl_from,
      tgl_to: selected.tgl_to,
    };
    const response =
      typeName === "alloy"
        ? await AxiosMasterGetWithParams("alloy", filterAlloy)
        : type
        ? await AxiosMasterGetWithParams("sampel", filter)
        : await AxiosMasterGetWithParams("perhiasan", {
            type: selected.type === "perhiasan" ? "MUTASI" : "PACKING",
            ...filter,
          });
    dispatch({
      type: GET_ALL_CETAK_BARCODE_SAMPLE,
      payload: response.data.result,
    });
  };
};
