import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { AxiosMasterPost } from "../../../Axios";
import { ToastKirim } from "../../../actions/toast";
import { reset } from "redux-form";
import { getBon } from "../../../helper";

export const sendSaldoAwal24K = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selector = state.form.FormSaldoAwal24K.values;
    const noBon = "SA/24K-" + getBon();
    let payload = {
      no_transaksi: noBon,
      jenis_trx: "24K",
      tanggal: selector.tanggal,
      kode_lokasi: "-",
      detail_saldo_awal: [
        {
          kode_supplier: "-",
          kode_customer: "-",
          kode_sales: "-",
          no_bon: "-",
          saldo_gr: 0,
          saldo_rp: 0,
          titip_gr: 0,
          titip_rp: 0,
          kode_barang:
            selector.kode_kategori.value !== undefined
              ? selector.kode_kategori.value
              : selector.kode_kategori,
          kode_bahan: "-",
          kode_ciok_rosok: "-",
          kadar_cetak: "0",
          bruto: selector.bruto,
          netto: selector.netto,
        },
      ],
    };
    try {
      await toast.promise(
        AxiosMasterPost("saldo-awal/simpan", payload),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(reset("FormSaldoAwal24K"));
    } catch (e) {
      dispatch(finishSend());
    }

    dispatch(finishSend());
  };
};
