import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setDashboard,
  setFICO,
  setJualBeliLM,
  setLaporan,
  setMaster,
  setSample,
  setStocking,
  setSupervisory,
  setTotalan,
  setTransaksi,
  setValidasi,
} from "./actions/titleAction";
import { Component } from "react";

import Swal from "sweetalert2";

const maptState = (state) => {
  return {
    title: state.title.title,
    dashboard: state.title.Dashboard,
    Master: state.title.Master,
    Sample: state.title.Sample,
    Stoking: state.title.Stoking,
    Transaksi: state.title.Transaksi,
    Fico: state.title.Fico,
    Validasi: state.title.Validasi,
    Laporan: state.title.Laporan,
    Supervisory: state.title.Supervisory,
    JualBeliLM: "d-none",
    Totalan: state.title.Totalan,
    Reparasi: state.title.Reparasi,
  };
};

class Sidebar extends Component {
  notReady() {
    Swal.fire({
      text: "Mohon maaf, Menu Belum Bisa Digunakan",
      position: "center",
      showConfirmButton: false,
      icon: "warning",
    }).then(() => window.history.back());
  }
  checkMenu(data1) {
    let check = localStorage.getItem("menu") || "kosong";
    if (check !== "kosong") {
      let data = JSON.parse(localStorage.getItem("menu") || []);
      return data.find((list) => list.menu_id === data1);
    }
  }

  render() {
    const type = process.env.REACT_APP_TYPE;
    return (
      <aside className={this.props.sidebar} data-sidebarbg="skin6">
        <div className="scroll-sidebar" data-sidebarbg="skin6">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li className={this.props.dashboard}>
                {this.props.aktif}
                <Link
                  className="sidebar-link sidebar-link"
                  to="/dashboard"
                  aria-expanded="false"
                  onClick={() => this.props.dispatch(setDashboard("Dashboard"))}
                >
                  <i className="fa fa-home" />
                  <span className="hide-menu">Dashboard</span>
                </Link>
              </li>
              <li className="list-divider" />
              <li className={this.props.Master}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-database"></i>
                  <span className="hide-menu">Data Master </span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Data Provinsi") !== undefined
                        ? "sidebar-item active "
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/provinsi"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Provinsi"))
                      }
                    >
                      <span className="hide-menu">Data Provinsi</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Kota")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/kota"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Kota"))
                      }
                    >
                      <span className="hide-menu">Data Kota</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Area")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/area"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Area"))
                      }
                    >
                      <span className="hide-menu">Data Kecamatan</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Daftar Bank")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/bank"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Daftar Bank"))
                      }
                    >
                      <span className="hide-menu">Daftar Bank</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Daftar Rekening Bank")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/rekening"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Daftar Rekening Bank"))
                      }
                    >
                      <span className="hide-menu">Daftar Rekening Bank</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Lokasi")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/lokasi"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Lokasi"))
                      }
                    >
                      <span className="hide-menu">Lokasi</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Salesman")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/salesman"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Salesman"))
                      }
                    >
                      <span className="hide-menu">Salesman</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/supplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Supplier"))
                      }
                    >
                      <span className="hide-menu">Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Cabang Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/cabangSupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Cabang Supplier"))
                      }
                    >
                      <span className="hide-menu">Cabang Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Customer")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/customer"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Customer"))
                      }
                    >
                      <span className="hide-menu">Customer</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data Kadar")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/kadar"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Kadar"))
                      }
                    >
                      <span className="hide-menu">Kadar</span>
                    </Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Data Reparasi")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/masterreparasi"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setMaster("Data Reparasi"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Data Reparasi</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Parameter Reparasi")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/parameterreparasi"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setMaster("Parameter Reparasi"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Parameter Reparasi</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("Data Klasifikasi Barang")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KlasifikasiBarang"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Klasifikasi barang"))
                      }
                    >
                      <span className="hide-menu">Klasifikasi Barang</span>
                    </Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("24K")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN" ||*/}
                  {/*        localStorage.getItem("level") === "SU"*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/master24k"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() => this.props.dispatch(setMaster("Data 24K"))}*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Master 24K</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("Kode Rongsok dan Ciok")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/koderosok"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setMaster("Data Kode Rongsok dan Ciok")
                        )
                      }
                    >
                      <span className="hide-menu">Kode Rongsok dan Ciok</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Parameter Harga Emas")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/parameter-harga-emas"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setMaster("Data Parameter Harga Emas")
                        )
                      }
                    >
                      <span className="hide-menu">Parameter Harga Emas</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Data  Transaksi")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/parameter"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setMaster("Data Parameter Transaksi Keuangan")
                        )
                      }
                    >
                      <span className="hide-menu">
                        Parameter Transaksi Keuangan
                      </span>
                    </Link>
                  </li>

                  <li
                    className={
                      this.checkMenu("Saldo Awal")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/saldoawal"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Data Saldo Awal"))
                      }
                    >
                      <span className="hide-menu">Saldo Awal</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Manage user")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/manageUser"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Manage User"))
                      }
                    >
                      <span className="hide-menu">Manage User</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.Sample}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-sitemap" aria-hidden="true" />
                  <span className="hide-menu">Data Sample</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Tambah Sample") !== undefined
                        ? "sidebar-item active "
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/addSample"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setSample("Tambah Sample"))
                      }
                    >
                      <span className="hide-menu">Data Sample</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Pindah Sample")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/pindahSample"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Pindah Sample"))
                      }
                    >
                      <span className="hide-menu">Pindah Sample</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Hancur Sample")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/hancurSample"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Hancur Sample"))
                      }
                    >
                      <span className="hide-menu">Hancur Sample</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Cetak Barcode Sample")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/cetakBarcodeSample"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setMaster("Cetak Barcode Sample"))
                      }
                    >
                      <span className="hide-menu">Cetak Barcode Sample</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.Stoking}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-archive" />
                  <span className="hide-menu">Stocking</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Barcode Barang")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/barcodeBarang"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Barcode Barang"))
                      }
                    >
                      <span className="hide-menu">Barcode Barang</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Barcode Sample")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/barcodeSample"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Barcode Sample"))
                      }
                    >
                      <span className="hide-menu">Barcode Sample</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Tambah 24K")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/tambah24k"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Tambah 24K"))
                      }
                    >
                      <span className="hide-menu">Tambah Saldo 24K</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Kirim Bahan 24K")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/kirimBahan24k"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Kirim Bahan 24K"))
                      }
                    >
                      <span className="hide-menu">Kirim Bahan 24K</span>
                    </Link>
                  </li>
                  {/* <li
                    className={
                      this.checkMenu("Kirim Stock")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/kirimStock"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Kirim Stock"))
                      }
                    >
                      <span className="hide-menu">Kirim Stock</span>
                    </Link>
                  </li> */}
                  {/* <li
                    className={
                      this.checkMenu("Mutasi Ciok")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/mutasiciok"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Mutasi Ciok & Rosok"))
                      }
                    >
                      <span className="hide-menu">Mutasi Ciok & Rosok</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Kirim Masak")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/kirimmasak"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Kirim Masak"))
                      }
                    >
                      <span className="hide-menu">Kirim Masak</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Terima Masak")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/terimamasak"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setStocking("Terima Masak"))
                      }
                    >
                      <span className="hide-menu">Terima Masak</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className={this.props.Transaksi}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-credit-card"></i>
                  <span className="hide-menu">Transaksi</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Penerimaan Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/terimasupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Penerimaan Supplier"))
                      }
                    >
                      <span className="hide-menu">Penerimaan Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Return Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/returnsupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Return Supplier"))
                      }
                    >
                      <span className="hide-menu">Return Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Tolakan Return Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/tolakanreturnsupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setTransaksi("Tolakan Return Supplier")
                        )
                      }
                    >
                      <span className="hide-menu">Tolakan Return Supplier</span>
                    </Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Transaksi Rekam Barang Barcode")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/ambilbarcode"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(*/}
                  {/*        setTransaksi("Transaksi Rekam Barang Barcode")*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">*/}
                  {/*      Transaksi Rekam Barang Barcode*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Batal Rekam Barang Barcode")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/closePengambilanBarang"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(*/}
                  {/*        setTransaksi("Batal Rekam Barang Barcode")*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">*/}
                  {/*      Batal Rekam Barang Barcode*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Transaksi Timbang Barang")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/transaksiTimbangBarang"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(*/}
                  {/*        setTransaksi("Transaksi Timbang Barang")*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">*/}
                  {/*      Transaksi Timbang Barang*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Batal Pengambilan Timbangan")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/batalPengambilanTimbangan"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(*/}
                  {/*        setTransaksi("Batal Pengambilan Timbangan")*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">*/}
                  {/*      Batal Pengambilan Timbangan*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Pre Order")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/PreOrder"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setTransaksi("Pre Order"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Pre Order</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Packing List")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/PackingList"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setTransaksi("Packing List"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Packing List</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("Transaksi Penjualan 24K")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TransaksiPenjualan24K"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setTransaksi("Transaksi Penjualan 24K")
                        )
                      }
                    >
                      <span className="hide-menu">Transaksi Penjualan 24K</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Transaksi Penjualan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TransaksiPenjualan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Transaksi Penjualan"))
                      }
                    >
                      <span className="hide-menu">Transaksi Penjualan</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Return Penjualan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ReturnPenjualan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Return Penjualan"))
                      }
                    >
                      <span className="hide-menu">Return Penjualan</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Close Penjualan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ClosePenjualan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Close Penjualan"))
                      }
                    >
                      <span className="hide-menu">Close Penjualan</span>
                    </Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Correction Note")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/CorrectionNote"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setTransaksi("Correction Note"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Correction Note</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("Kirim Stock Bayar")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KirimStockBayar"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Kirim Stock Bayar"))
                      }
                    >
                      <span className="hide-menu">Kirim Stock Bayar</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Kirim Stock Retur")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KirimStockRetur"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Kirim Stock Retur"))
                      }
                    >
                      <span className="hide-menu">Kirim Stock Retur</span>
                    </Link>
                  </li>
                  {/* <li
                    className={
                      this.checkMenu("Return Penjualan Non-Toko")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ReturnPenjualanNonToko"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setTransaksi("Return Penjualan Non-Toko")
                        )
                      }
                    >
                      <span className="hide-menu">
                        Return Penjualan Non-Toko
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Tolakan Return Customer")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TolakanReturnCustomer"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setTransaksi("TolakanReturn Customer")
                        )
                      }
                    >
                      <span className="hide-menu">Tolakan Return Customer</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Close Transaksi Penjualan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/CloseTransaksiPenjualan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setTransaksi("Close Transaksi Penjualan")
                        )
                      }
                    >
                      <span className="hide-menu">
                        Close Transaksi Penjualan
                      </span>
                    </Link>
                  </li> */}
                  <li
                    className={
                      this.checkMenu("Rekap Bon Penjualan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/RekapBonPenjualan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTransaksi("Rekap Bon Penjualan"))
                      }
                    >
                      <span className="hide-menu">Rekap Bon Penjualan</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.Fico}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fas fa-cogs"></i>
                  <span className="hide-menu">FICO</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Kelola Uang Bank ")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KelolaUangBank"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Kelola Uang Bank"))
                      }
                    >
                      <span className="hide-menu">Kelola Uang Bank</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Kelola Uang Kas")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KelolaUangKas"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Kelola Uang Kas"))
                      }
                    >
                      <span className="hide-menu">Kelola Uang Kas</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Mutasi Cash Dan Bank")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/MutasiCashDanBank"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Mutasi Cash Dan Bank"))
                      }
                    >
                      <span className="hide-menu">Mutasi Cash Dan Bank</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Klasifikasi Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KlasifikasiSupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Klasifikasi Supplier"))
                      }
                    >
                      <span className="hide-menu">Klasifikasi Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Klasifikasi Customer")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/KlasifikasiCustomer"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Klasifikasi Customer"))
                      }
                    >
                      <span className="hide-menu">Klasifikasi Customer</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Validasi Transfer")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ValidasiTransferCustomer"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Validasi Transfer"))
                      }
                    >
                      <span className="hide-menu">Validasi Transfer</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Validasi Giro")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ValidasiPembayaranGiro"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setFICO("Validasi Giro"))
                      }
                    >
                      <span className="hide-menu">Validasi Giro</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.Validasi}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-check-square"></i>
                  <span className="hide-menu">Validasi</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Validasi Barang")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/validasiBarang"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setValidasi("Validasi Barang"))
                      }
                    >
                      <span className="hide-menu">Validasi Barang</span>
                    </Link>
                  </li>
                  {/*HIDE VALIDAIS PO*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Validasi PO")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN" ||*/}
                  {/*        localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/validasiPO"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setValidasi("Validasi PO"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Validasi PO</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("Validasi Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/validasiSupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setValidasi("Validasi Supplier"))
                      }
                    >
                      <span className="hide-menu">Validasi Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Validasi Customer")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/validasiCustomer"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setValidasi("Validasi Customer"))
                      }
                    >
                      <span className="hide-menu">Validasi Customer</span>
                    </Link>
                  </li>
                  {/* <li
                    className={
                      this.checkMenu("Validasi Reparasi")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? type === "real"
                          ? "sidebar-item active"
                          : "sidebar-item active d-none"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/validasiReparasi"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setValidasi("Validasi Reparasi"))
                      }
                    >
                      <span className="hide-menu">Validasi Reparasi</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className={this.props.Laporan}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-book" aria-hidden="true"></i>
                  <span className="hide-menu">Laporan</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Laporan Keuangan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporanKeuangan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan Keuangan"))
                      }
                    >
                      <span className="hide-menu">Laporan Keuangan</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Laporan Supplier")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporanSupplier"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan Supplier"))
                      }
                    >
                      <span className="hide-menu">Laporan Supplier</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("laporan Stok")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporanStok"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan Stok"))
                      }
                    >
                      <span className="hide-menu">Laporan Stok</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("laporan Sampel")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporanSampel"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan Sampel"))
                      }
                    >
                      <span className="hide-menu">Laporan Sampel</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("laporan Customer")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporanCustomer"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan Customer"))
                      }
                    >
                      <span className="hide-menu">Laporan Customer</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("laporan 24K")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporan24K"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan 24K"))
                      }
                    >
                      <span className="hide-menu">Laporan 24K</span>
                    </Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("laporan Reparasi")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/laporanReparasi"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setLaporan("Laporan Reparasi"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Laporan Reparasi</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("laporan Timbang Stock")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/laporanTimbangStock"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(setLaporan("Laporan Timbang Stock"))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Laporan Timbang Stock</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("laporan Validasi")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? type === "real"
                          ? "sidebar-item active"
                          : "sidebar-item active d-none"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/laporanValidasi"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setLaporan("Laporan Validasi"))
                      }
                    >
                      <span className="hide-menu">Laporan Validasi</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.Supervisory}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i data-feather="grid" className="feather-icon"></i>
                  <span className="hide-menu">Supervisory</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  {/* <li
                    className={
                      this.checkMenu("Parameter")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/parameterSupervisory"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setSupervisory("Parameter"))
                      }
                    >
                      <span className="hide-menu">Parameter</span>
                    </Link>
                  </li> */}
                  {/* <li
                    className={
                      this.checkMenu("Cetak History User")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/historyUser"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setSupervisory("Cetak History User")
                        )
                      }
                    >
                      <span className="hide-menu">Cetak History User</span>
                    </Link>
                  </li> */}
                  <li
                    className={
                      this.checkMenu("Cetak Barcode")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/CetakBarcode"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setSupervisory("Cetak Barcode"))
                      }
                    >
                      <span className="hide-menu">Cetak Barcode</span>
                    </Link>
                  </li>
                  {/*<li*/}
                  {/*  className={*/}
                  {/*    this.checkMenu("Cetak Barcode Model")*/}
                  {/*      ? "sidebar-item active"*/}
                  {/*      : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
                  {/*      ? type === "real"*/}
                  {/*        ? "sidebar-item active"*/}
                  {/*        : "sidebar-item active d-none"*/}
                  {/*      : "sidebar-item active d-none"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Link*/}
                  {/*    to="/CetakBarcodeModel"*/}
                  {/*    className="sidebar-link"*/}
                  {/*    onClick={() =>*/}
                  {/*      this.props.dispatch(*/}
                  {/*        setSupervisory("Cetak Barcode Model")*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <span className="hide-menu">Cetak Barcode Model</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li
                    className={
                      this.checkMenu("Cetak Ulang Bukti")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/cetakUlangBukti"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setSupervisory("Cetak Ulang Bukti"))
                      }
                    >
                      <span className="hide-menu">Cetak Ulang Bukti</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.JualBeliLM}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="far fa-money-bill-alt"></i>
                  <span className="hide-menu">Jual/Beli LM</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Transaksi Penjualan LM")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TransaksiPenjualanLM"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setJualBeliLM("Transaksi Penjualan LM")
                        )
                      }
                    >
                      <span className="hide-menu">Jual LM</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Transaksi Pembelian LM")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TransaksiPembelianLM"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setJualBeliLM("Transaksi Pembelian LM")
                        )
                      }
                    >
                      <span className="hide-menu">Beli LM</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Validasi Stok Jual LM")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ValidasiStokJualLM"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setJualBeliLM("Validasi Stok Jual LM")
                        )
                      }
                    >
                      <span className="hide-menu">Validasi Stok Jual LM</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Validasi Stok Beli LM")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/ValidasiStokBeliLM"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(
                          setJualBeliLM("Validasi Stok Beli LM")
                        )
                      }
                    >
                      <span className="hide-menu">Validasi Stok Beli LM</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Bayar Jual LM")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/PembayaranPenjualanLM"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setJualBeliLM("Bayar Jual LM"))
                      }
                    >
                      <span className="hide-menu">Bayar Jual LM</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Bayar Beli LM")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/PembayaranPembelianLM"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setJualBeliLM("Bayar Beli LM"))
                      }
                    >
                      <span className="hide-menu">Bayar Beli LM</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.Totalan}>
                <a
                  className="sidebar-link has-arrow"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                  href="/#"
                >
                  <i className="fa fa-shopping-basket"></i>
                  <span className="hide-menu">Totalan</span>
                </a>
                <ul
                  aria-expanded="false"
                  className="collapse  first-level base-level-line"
                >
                  <li
                    className={
                      this.checkMenu("Totalan Timbangan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? type === "real"
                          ? "sidebar-item active"
                          : "sidebar-item active d-none"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TotalanTimbangan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTotalan("Totalan Timbangan"))
                      }
                    >
                      <span className="hide-menu">Totalan Timbangan</span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.checkMenu("Timbangan Stock")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TimbanganStock"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTotalan("Timbangan Stock"))
                      }
                    >
                      <span className="hide-menu">Timbangan Stock</span>
                    </Link>
                  </li>
                  {/* <li
                    className={
                      this.checkMenu("Totalan Barcode")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/TotalanBarcode"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTotalan("Totalan Barcode"))
                      }
                    >
                      <span className="hide-menu">Totalan Barcode</span>
                    </Link>
                  </li> */}
                  <li
                    className={
                      this.checkMenu("Selisih Timbangan")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN" ||
                          localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/SelisihTimbangan"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTotalan("Selisih Timbangan"))
                      }
                    >
                      <span className="hide-menu">Selisih Timbangan</span>
                    </Link>
                  </li>
                  {/* <li
                    className={
                      this.checkMenu("Selisih Timbang Rongsok")
                        ? "sidebar-item active"
                        : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"
                        ? "sidebar-item active"
                        : "sidebar-item active d-none"
                    }
                  >
                    <Link
                      to="/SelisihTimbangRosok"
                      className="sidebar-link"
                      onClick={() =>
                        this.props.dispatch(setTotalan("Selisih Timbang Rosok"))
                      }
                    >
                      <span className="hide-menu">Selisih timbang Rosok</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
              {/*<li className={type === "real" ? this.props.Reparasi : "d-none"}>*/}
              {/*  <a*/}
              {/*    className="sidebar-link has-arrow"*/}
              {/*    aria-expanded="false"*/}
              {/*    style={{ cursor: "pointer" }}*/}
              {/*    href="/#"*/}
              {/*  >*/}
              {/*    <i className="fa fa-wrench"></i>*/}
              {/*    <span className="hide-menu">Reparasi</span>*/}
              {/*  </a>*/}
              {/*  <ul*/}
              {/*    aria-expanded="false"*/}
              {/*    className="collapse first-level base-level-line"*/}
              {/*  >*/}
              {/*    <li*/}
              {/*      className={*/}
              {/*        this.checkMenu("Transaksi Reparasi")*/}
              {/*          ? "sidebar-item active"*/}
              {/*          : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
              {/*          ? "sidebar-item active"*/}
              {/*          : "sidebar-item active d-none"*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Link*/}
              {/*        to="/TranskasiReparasi"*/}
              {/*        className="sidebar-link"*/}
              {/*        onClick={() =>*/}
              {/*          this.props.dispatch(setReparasi("Transaksi Reparasi"))*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <span className="hide-menu">Transaksi Reparasi</span>*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*    <li*/}
              {/*      className={*/}
              {/*        this.checkMenu("Selesai Reparasi")*/}
              {/*          ? "sidebar-item active"*/}
              {/*          : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
              {/*          ? "sidebar-item active"*/}
              {/*          : "sidebar-item active d-none"*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Link*/}
              {/*        to="/SelesaiReparasi"*/}
              {/*        className="sidebar-link"*/}
              {/*        onClick={() =>*/}
              {/*          this.props.dispatch(setReparasi("Selesai Reparasi"))*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <span className="hide-menu">Selesai Reparasi</span>*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*    <li*/}
              {/*      className={*/}
              {/*        this.checkMenu("Serah Reparasi")*/}
              {/*          ? "sidebar-item active"*/}
              {/*          : localStorage.getItem("level") === "OWN"  || localStorage.getItem("level") === "SU"*/}
              {/*          ? "sidebar-item active"*/}
              {/*          : "sidebar-item active d-none"*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Link*/}
              {/*        to="/SerahReparasi"*/}
              {/*        className="sidebar-link"*/}
              {/*        onClick={() =>*/}
              {/*          this.props.dispatch(setReparasi("Serah Reparasi"))*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <span className="hide-menu">Serah Reparasi</span>*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</li>*/}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default connect(maptState, null)(Sidebar);
