import {
  finishSend,
  isExist,
  isExistSingle,
  progressSend,
} from "../../../actions/LogicAction";
import { getLocal, saveLocal } from "../../../encrypt";
import { change, reset } from "redux-form";
import {
  hideModal,
  hideModalSecond,
  showModalSecond,
} from "../../../actions/ModalAction";
import { getToday } from "../../../getDate";
import {
  convertKadarCetak,
  findBy,
  formatGram,
  formatRp,
  FormatterNumber,
  getBon,
  getUserID,
  multipleDeleteLocal,
  printInfo,
  purifiedData,
  removeComma,
  setFocus,
} from "../../../helper";
import PrintNota from "../../../Stocking/Component/PrintNota";
import { toast } from "react-toastify";
import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterGetWithParams,
  AxiosMasterPost,
} from "../../../Axios";
import { ToastAmbil, ToastKirim } from "../../../actions/toast";
import { getUUID } from "../../../actions/fakturAction";
import Swal from "sweetalert2";

export const GET_TERIMA_SUPPLIER_TEMP = "GET_TERIMA_SUPPLIER_TEMP";
export const SAVE_PREV_NO_TERIMA = "SAVE_PREV_NO_TERIMA";
export const SET_EDIT_TERIMA_SUPPLIER = "SET_EDIT_TERIMA_SUPPLIER";
export const GET_LIST_UNVALID_TERIMA_BARANG = "GET_LIST_UNVALID_TERIMA_BARANG";
export const GET_RETURN_SUPPLIER_TEMP = "GET_RETURN_SUPPLIER_TEMP";
export const SET_TOTAL_NW_TERIMA_SUPPLIER = "SET_TOTAL_NW_TERIMA_SUPPLIER";
export const SET_TOTAL_BRUTO_TERIMA_SUPPLIER =
  "SET_TOTAL_BRUTO_TERIMA_SUPPLIER";
export const SET_TOTAL_PKG_TERIMA_SUPPLIER = "SET_TOTAL_PKG_TERIMA_SUPPLIER";
export const SET_TOTAL_GROSS_TERIMA_SUPPLIER =
  "SET_TOTAL_GROSS_TERIMA_SUPPLIER";
export const SET_TOTAL_ONGKIR_TERIMA_SUPPLIER =
  "SET_TOTAL_ONGKIR_TERIMA_SUPPLIER";
export const SET_EDIT_PENERIMAAN_SUPP = "SET_EDIT_PENERIMAAN_SUPP";
export const SET_BARANG_ALLOY = "SET_BARANG_ALLOY";

export const getTerimaSupplierTemp = () => {
  return async (dispatch) => {
    const result = await getLocal("TerimaSupplierTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TerimaSupplierHead", "totalNW", formatGram(totalNW)));
    dispatch(change("TerimaSupplierHead", "totalNWExt", formatGram(totalNW)));
    dispatch({
      type: GET_TERIMA_SUPPLIER_TEMP,
      payload: {
        data: result,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const setTotalNW = (total) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTAL_NW_TERIMA_SUPPLIER,
      payload: {
        data: localStorage.getItem("totalNW") || 0,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const setEditPenerimaanSupp = () => {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_PENERIMAAN_SUPP,
      payload: {
        data: localStorage.getItem("EditTerimaSupplier") || "false",
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};
export const changeBarangAlloy = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BARANG_ALLOY,
      payload: data,
    });
  };
};
export const setPrevNoTerima = () => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PREV_NO_TERIMA,
      payload: localStorage.getItem("prev_no_terima") || "-",
    });
  };
};

export const getReturnSupplierTemp = () => {
  return (dispatch) => {
    dispatch({
      type: GET_RETURN_SUPPLIER_TEMP,
      payload: {
        data:
          JSON.parse(localStorage.getItem("ReturnSupplier")) === null
            ? []
            : JSON.parse(localStorage.getItem("ReturnSupplier")),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const getUnvalidTerimaBarang = () => {
  return async (dispatch, getState) => {
    const response = await AxiosMasterGet("terima-barang/unvalid");
    try {
      if (response.data.length === 0) {
        dispatch({
          type: GET_LIST_UNVALID_TERIMA_BARANG,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_LIST_UNVALID_TERIMA_BARANG,
          payload: response.data.filter(
            (x) =>
              x.kode_supplier === localStorage.getItem("kodeSupplier") || "AMG"
          ),
        });
      }
    } catch (e) {
      dispatch({
        type: GET_LIST_UNVALID_TERIMA_BARANG,
        payload: [],
      });
      dispatch(finishSend());
    }
  };
};

export const saveEditTemp = (data) => {
  return async (dispatch, getState) => {
    dispatch(change("TerimaSupplierHead", "keterangan", "-"));
    dispatch({
      type: SAVE_PREV_NO_TERIMA,
      payload: data.no_terima,
    });
    dispatch({
      type: SET_EDIT_PENERIMAAN_SUPP,
      payload: "true",
    });
    let number = 1;
    for (let item of data.detail_barang) {
      delete item._id;
      item.no = number;
      await isExist({
        target: "kode_barang",
        target2: "kode_bahan",
        name: "TerimaSupplierTemp",
        payload: item,
        value: item.kode_barang.split("/")[0],
        value2: item.kode_barang.split("/")[1],
      });
      const result = await getLocal("TerimaSupplierTemp");
      const totalNW = result
        .map((x) => removeComma(x.netto))
        .reduce((a, b) => a + b, 0);
      dispatch(change("TerimaSupplierHead", "totalNW", formatGram(totalNW)));
      dispatch(change("TerimaSupplierHead", "totalNWExt", formatGram(totalNW)));
      number += 1;
    }
    localStorage.setItem("EditTerimaSupplier", "true");
    localStorage.setItem("prev_no_terima", data.no_terima);
    dispatch(getTerimaSupplierTemp());
    dispatch(setEditPenerimaanSupp());
    dispatch(setPrevNoTerima());
    dispatch(hideModal());
    dispatch(finishSend());
  };
};

export const setEditForm = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_EDIT_TERIMA_SUPPLIER,
      payload: data,
    });
  };
};

export const saveTerimaSupplierTemp = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const index = (await getLocal("TerimaSupplierTemp")) || [];
    const state = getState();
    const selected = state.form.TerimaSupplier.values;
    purifiedData(selected);
    selected.no = index.length;
    selected.kode_bahan = selected.kode_barang.split("/")[1];
    selected.kode_barang = selected.kode_barang.split("/")[0];
    selected.netto = FormatterNumber(selected.netto);
    selected.bruto = FormatterNumber(selected.bruto);
    selected.gross = FormatterNumber(selected.gross);
    delete selected.no_job_order;
    await isExist({
      target: "kode_barang",
      target2: "kode_bahan",
      name: "TerimaSupplierTemp",
      payload: selected,
      value: selected.kode_barang,
      value2: selected.kode_bahan,
    });
    const result = await getLocal("TerimaSupplierTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TerimaSupplierHead", "totalNW", formatGram(totalNW)));
    dispatch(change("TerimaSupplierHead", "totalNWExt", formatGram(totalNW)));
    dispatch(getTerimaSupplierTemp());
    toast.success("Barang Berhasil Ditambahkan");
    dispatch(reset("TerimaSupplier"));
    setFocus("Nomor Job Order");
    dispatch(finishSend());
  };
};

export const saveTerimaSupplierEditTemp = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.TerimaSupplierEdit.values;
    purifiedData(selected);
    selected.kode_bahan = selected.kode_barang.split("/")[1];
    selected.kode_barang = selected.kode_barang.split("/")[0];
    selected.netto = FormatterNumber(selected.netto);
    selected.bruto = FormatterNumber(selected.bruto);
    selected.gross = FormatterNumber(selected.gross);
    selected.isEditKadarBeli =
      parseFloat(selected.kadar_beli) !== parseFloat(selected.kadar_beli_prev);
    delete selected.kadar_beli_prev;
    delete selected.no_job_order;
    const res = await isExistSingle({
      target: "no",
      name: "TerimaSupplierTemp",
      payload: selected,
      value: selected.no,
      replace: true,
    });
    const result = await getLocal("TerimaSupplierTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TerimaSupplierHead", "totalNW", formatGram(totalNW)));
    dispatch(change("TerimaSupplierHead", "totalNWExt", formatGram(totalNW)));
    dispatch(getTerimaSupplierTemp());
    if (res !== "DECLINED") {
      toast.success("Barang Berhasil Dirubah");
    }

    dispatch(hideModal());
    dispatch(finishSend());
  };
};
export const otorisasiEditKadarBeli = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let state = getState();
    const selected = state.form.FormOtorisasi.values;
    purifiedData(selected);
    AxiosMasterPost("users/check", selected)
      .then(async () => {
        dispatch(hideModalSecond());
        const selected = state.form.TerimaSupplierHead.values;
        purifiedData(selected);
        var jenis = selected.supplier ? selected.supplier : "DEFALT | DEFAULT";
        var result_explode = jenis.split("|");
        var noBon = "TS-" + getBon();
        const detail_barang = await getLocal("TerimaSupplierTemp");
        var result = await PostTerimaSupplier({
          detail_barang: detail_barang,
          dispatch: dispatch,
          payload: {
            no_terima: state.provinsi.noFaktur,
            prev_no_terima: state.provinsi.prev_no_terima,
            is_edit: state.provinsi.prev_no_terima !== "-",
            no_bon: noBon,
            kode_supplier: result_explode[0],
            total_netto: FormatterNumber(selected.totalNW),
            total_netto_ext: FormatterNumber(selected.totalNWExt),
            tanggal_invoice: selected.tanggal || getToday(),
            tanggal_barang: selected.tanggal || getToday(),
            detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
          },
          result_explode: result_explode,
          selected: selected,
        });
        if (result.includes("E11000")) {
          multipleDeleteLocal(["noFaktur"]);
          dispatch(getUUID());
          state = getState();
          await PostTerimaSupplier({
            detail_barang: detail_barang,
            dispatch: dispatch,
            payload: {
              no_terima: state.provinsi.noFaktur,
              prev_no_terima: state.provinsi.prev_no_terima,
              is_edit: state.provinsi.prev_no_terima !== "-",
              no_bon: noBon,
              kode_supplier: result_explode[0],
              total_netto: FormatterNumber(selected.totalNW),
              total_netto_ext: FormatterNumber(selected.totalNWExt),
              tanggal_invoice: selected.tanggal || getToday(),
              tanggal_barang: selected.tanggal || getToday(),
              detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
            },
            result_explode: result_explode,
            selected: selected,
          });
        }
        localStorage.setItem("prev_no_terima", "-");
        localStorage.setItem("EditTerimaSupplier", "false");
      })
      .catch((err) => {
        toast.error("User ID Atau Password Salah!");
        dispatch(finishSend());
      });
  };
};

export const deleteLocalTerimaSupplierTemp = (nama, index, getFunction) => {
  return async (dispatch) => {
    dispatch(progressSend());
    const data = await getLocal(nama);
    data.splice(index, 1);
    await saveLocal(nama, data);
    const result = await getLocal("TerimaSupplierTemp");
    const totalNW = result
      .map((x) => parseFloat(removeComma(x.netto)))
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    dispatch(change("TerimaSupplierHead", "totalNWExt", formatGram(totalNW)));
    dispatch(change("TerimaSupplierHead", "totalNW", formatGram(totalNW)));
    dispatch(getTerimaSupplierTemp());
    dispatch(finishSend());
  };
};

export const sendTerimaSupplier = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let state = getState();
    const selected = state.form.TerimaSupplierHead.values;
    purifiedData(selected);
    var jenis = selected.supplier ? selected.supplier : "DEFALT | DEFAULT";
    var result_explode = jenis.split("|");
    var noBon = "TS-" + getBon();
    const detail_barang = await getLocal("TerimaSupplierTemp");
    const barangfind = detail_barang.find(
      (val) => val.isEditKadarBeli === true || val.isEditKadarBeli === "true"
    );
    if (barangfind !== undefined) {
      dispatch(showModalSecond());
    } else {
      var result = await PostTerimaSupplier({
        detail_barang: detail_barang,
        dispatch: dispatch,
        payload: {
          no_terima: state.provinsi.noFaktur,
          prev_no_terima: state.provinsi.prev_no_terima,
          is_edit: state.provinsi.prev_no_terima !== "-",
          status_alloy: state.provinsi.is_alloy,
          no_bon: noBon,
          kode_supplier: result_explode[0],
          total_netto: FormatterNumber(selected.totalNW),
          total_netto_ext: FormatterNumber(selected.totalNWExt),
          tanggal_invoice: selected.tanggal || getToday(),
          tanggal_barang: selected.tanggal || getToday(),
          detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
        },
        result_explode: result_explode,
        selected: selected,
      });
      if (result.includes("E11000")) {
        multipleDeleteLocal(["noFaktur"]);
        dispatch(getUUID());
        state = getState();
        await PostTerimaSupplier({
          detail_barang: detail_barang,
          dispatch: dispatch,
          payload: {
            no_terima: state.provinsi.noFaktur,
            prev_no_terima: state.provinsi.prev_no_terima,
            is_edit: state.provinsi.prev_no_terima !== "-",
            no_bon: noBon,
            kode_supplier: result_explode[0],
            total_netto: FormatterNumber(selected.totalNW),
            total_netto_ext: FormatterNumber(selected.totalNWExt),
            tanggal_invoice: selected.tanggal || getToday(),
            tanggal_barang: selected.tanggal || getToday(),
            detail_barang: detail_barang.map(({ no, _id, ...rest }) => rest),
          },
          result_explode: result_explode,
          selected: selected,
        });
      }
      localStorage.setItem("prev_no_terima", "-");
      localStorage.setItem("EditTerimaSupplier", "false");
      localStorage.removeItem("isAlloy");
    }
  };
};

export const PostTerimaSupplier = async ({
  dispatch,
  payload,
  detail_barang,
  selected,
  result_explode,
}) => {
  try {
    var response = await toast.promise(
      AxiosMasterPost("trx-supplier/terima-barang", payload),
      ToastKirim
    );
    print(
      detail_barang,
      response.data.no_bon,
      selected.tanggal,
      result_explode[0]
    );
    multipleDeleteLocal([
      "TerimaSupplierTemp",
      "noBon",
      "tanggal",
      "tanggalBarang",
      "namaSupplier",
      "kodeSupplier",
      "keterangan",
      "totalNW",
      "noFaktur",
      "temp-terimaSupplierSupp",
      "temp-terimaSupplierKeterangan",
      "temp-terimaSupplierDate",
    ]);
    dispatch(getUUID());
    dispatch(finishSend());
    dispatch(getTerimaSupplierTemp());
    dispatch(reset("TerimaSupplierHead"));
    dispatch(change("TerimaSupplierHead", "tanggal", getToday()));
    window.location.reload();
    return "BERHASIL";
  } catch (e) {
    dispatch(finishSend());
    return e?.response?.data;
  }
};

export const findJobOrder = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.TerimaSupplier.values;
    purifiedData(selected);
    if (!selected.no_job_order) {
      dispatch(finishSend());
      return false;
    }
    let response = await toast.promise(
      AxiosMasterGetWithParams("job-order", {
        no_job_order: selected.no_job_order,
      }),
      ToastAmbil
    );
    dispatch(change("TerimaSupplier", "bruto", response.data.bruto));
    dispatch(change("TerimaSupplier", "qty", response.data.qty));
    dispatch(
      change("TerimaSupplier", "berat_per_item", response.data.berat_per_item)
    );
    dispatch(
      change("TerimaSupplier", "kode_barang", {
        value: response.data.kode_barang + "/" + response.data.kode_bahan,
        label: response.data.kode_barang + " / " + response.data.kode_bahan,
      })
    );
    const result = await findBy(
      state.barang.listBarang,
      "kode_barang",
      response.data.kode_barang,
      "kode_bahan",
      response.data.kode_bahan
    );
    dispatch(
      change(
        "TerimaSupplier",
        "kadar_cetak",
        convertKadarCetak(result.kode_bahan, result.kadar_cetak)
      )
    );
    dispatch(change("TerimaSupplier", "berat_per_item", result.berat_per_item));
    dispatch(change("TerimaSupplier", "kadar_beli", result.kadar_beli));
    dispatch(finishSend());
  };
};

export const findJobOrderEdit = () => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    const state = getState();
    const selected = state.form.TerimaSupplierEdit.values;
    purifiedData(selected);
    if (!selected.no_job_order) {
      dispatch(finishSend());
      return false;
    }
    let response = await toast.promise(
      AxiosMasterGetWithParams("job-order", {
        no_job_order: selected.no_job_order,
      }),
      ToastAmbil
    );
    dispatch(change("TerimaSupplierEdit", "bruto", response.data.bruto));
    dispatch(change("TerimaSupplierEdit", "qty", response.data.qty));
    dispatch(
      change(
        "TerimaSupplierEdit",
        "berat_per_item",
        response.data.berat_per_item
      )
    );
    dispatch(
      change("TerimaSupplierEdit", "kode_barang", {
        value: response.data.kode_barang + "/" + response.data.kode_bahan,
        label: response.data.kode_barang + " / " + response.data.kode_bahan,
      })
    );
    const result = await findBy(
      state.barang.listBarang,
      "kode_barang",
      response.data.kode_barang,
      "kode_bahan",
      response.data.kode_bahan
    );
    dispatch(
      change(
        "TerimaSupplierEdit",
        "kadar_cetak",
        convertKadarCetak(result.kode_bahan, result.kadar_cetak)
      )
    );
    dispatch(
      change("TerimaSupplierEdit", "berat_per_item", result.berat_per_item)
    );
    dispatch(change("TerimaSupplierEdit", "kadar_beli", result.kadar_beli));
    dispatch(finishSend());
  };
};

export const deleteFaktur = (data) => {
  return async (dispatch) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Faktur Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        AxiosMasterDelete("trx-supplier?no_bon=" + data.no_bon)
          .then(() => {
            AxiosMasterGet("terima-barang/unvalid")
              .then((res) => {
                dispatch({
                  type: GET_LIST_UNVALID_TERIMA_BARANG,
                  payload: res.data.filter(
                    (x) =>
                      x.kode_supplier ===
                        localStorage.getItem("kodeSupplier") || "AMG"
                  ),
                });
              })
              .catch(() => {
                dispatch({
                  type: GET_LIST_UNVALID_TERIMA_BARANG,
                  payload: [],
                });
              });
          })
          .then(() => {
            toast.success("Berhasil Menghapus Data");
            dispatch(finishSend());
          })
          .catch(() => {
            toast.error("Gagal Menghapus Data");
            dispatch(finishSend());
          });
      } else {
        dispatch(finishSend());
      }
    });
  };
};

function print(table, noBon, tanggal, supplier) {
  var tableRows = [];
  table.forEach((ticket, i) => {
    const ticketData = [
      { content: ++i },
      { content: ticket.kode_barang },
      { content: ticket.kode_bahan },
      { content: ticket.qty, styles: { halign: "right" } },
      { content: ticket.kadar_cetak, styles: { halign: "right" } },
      { content: formatGram(ticket.plastik), styles: { halign: "right" } },
      { content: formatGram(ticket.kulit), styles: { halign: "right" } },
      { content: ticket.pkg, styles: { halign: "right" } },
      { content: formatGram(ticket.bruto), styles: { halign: "right" } },
      { content: ticket.kadar_beli, styles: { halign: "right" } },
      { content: formatGram(ticket.netto, 3), styles: { halign: "right" } },
      { content: formatRp(ticket.ongkos_rp), styles: { halign: "right" } },
    ];
    // push each tickcet's info into a row
    tableRows.push(ticketData);
  });
  const footer = [
    "",
    "",
    "",
    {
      content: table
        .map((list) => removeComma(list.qty))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    "",
    "",
    "",
    {
      content: table
        .map((list) => removeComma(list.pkg))
        .reduce((a, b) => a + b, 0),
      styles: { halign: "right" },
    },
    {
      content: formatGram(
        table.map((list) => removeComma(list.bruto)).reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    "",
    {
      content: formatGram(
        table.map((list) => removeComma(list.netto)).reduce((a, b) => a + b, 0),
        3
      ),
      styles: { halign: "right" },
    },
    {
      content: formatRp(
        table
          .map((list) => removeComma(list.ongkos_rp))
          .reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
    {
      content: formatRp(
        table
          .map((list) => removeComma(list.harga_beli))
          .reduce((a, b) => a + b, 0)
      ),
      styles: { halign: "right" },
    },
  ];
  // tableRows.push(footer);
  var columTable = [
    [
      { content: "No" },
      { content: "Kode\nBarang" },
      { content: "Kode\nBahan" },
      { content: "Qty", styles: { halign: "right" } },
      { content: "Cap\nKode", styles: { halign: "right" } },
      { content: "P", styles: { halign: "right" } },
      { content: "K", styles: { halign: "right" } },
      { content: "PKG\n", styles: { halign: "right" } },
      { content: "Berat\n(Gr)", styles: { halign: "right" } },
      { content: "Harga\n(Gr)", styles: { halign: "right" } },
      { content: "Murni\n(Gr)", styles: { halign: "right" } },
      { content: "Ongkos\n(Rp)", styles: { halign: "right" } },
    ],
  ];
  PrintNota(
    "No Bon",
    noBon,
    "Tanggal",
    tanggal,
    "",
    "",
    "Supplier",
    supplier,
    getUserID(),
    getToday(),
    "",
    columTable,
    "Terima Barang Supplier",
    tableRows,
    [footer, printInfo()]
  );
}
